import React, { useState, useRef, useEffect } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Flex, IconButton, Avatar, Text, Image, Heading, Box } from '@chakra-ui/react'

export default function Depoimentos(){
  const [isIntersecting, setIsIntersecting] = useState(false);
  // const [isIntersectingdois, setIsIntersectingdois] = useState(false);

  const ref = useRef(null);
  // const refdois = useRef(null);

  useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsIntersecting(entry.isIntersecting);
        },
        { rootMargin: "-80px" }
      );
      console.log(isIntersecting);
      observer.observe(ref.current);
      return () => observer.disconnect();
  }, [isIntersecting]);
  

  useEffect(() => {
      if (isIntersecting) {
        ref.current.querySelectorAll("div, h2").forEach((el) => {
          el.classList.add("slide-in");
          el.classList.remove("slide-out-left")
        });
      } else {
        ref.current.querySelectorAll("div, h2").forEach((el) => {
          el.classList.remove("slide-in");
          el.classList.add("slide-out-left")
        });
      }
  }, [isIntersecting]);

  return (
    <section className="bg-bg-depoimentos bg-fixed bg-purple-950 bg-blend-overlay bg-cover bg-bottom py-20 flex flex-col items-center justify-center" id="depoimentos">
      <div className="container flex items-center justify-center flex-col px-4 w-full">
        <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">Veja os depoimentos de quem já contratou e aprovou os planos da Vmar</h2>
        <div ref={ref} className="flex flex-wrap justify-around gap-12 lg:gap-2 py-20 px-4 w-full">
            <div class="transition-all duration-[850ms] relative max-w-xs sm:max-w-md mx-auto flex flex-col py-6 px-8 bg-gradient-to-b from-white to-[#F7F7F7] rounded-lg shadow-lg">
                <img
                src="/images/lucas.jpg"
                alt=""
                class="absolute rounded-full w-14 h-14 -left-6 -top-6"
                />
                <p class="text-purple-950 text-lg font-medium">
                    “Sou cliente da Vmar desde que me mudei e só tenho elogios ao provedor. A conexão é estável e nunca me deixou na mão, nem para assistir as minhas séries, nem para o home office"
                </p>
                <p class="mt-6 mb-2 text-purple-950">Lucas Rodrigues</p>
            </div>
            <div class="transition-all duration-[850ms] relative max-w-xs sm:max-w-md mx-auto flex flex-col py-6 px-8 bg-gradient-to-b from-white to-[#F7F7F7] rounded-lg shadow-lg">
                <img
                src="/images/matheus.jpg"
                alt=""
                class="absolute rounded-full w-14 h-14 -left-6 -top-6"
                />
                <p class="text-purple-950 text-lg font-medium">
                    “O que amo na Vmar é a qualidade da rede, sempre entrega mais que contratado. Meus torrents agradecem”
                </p>
                <p class="mt-6 mb-2 text-purple-950">Matheus Castanheira</p>
            </div>
            <div class="transition-all duration-[850ms] relative max-w-xs sm:max-w-md mx-auto flex flex-col py-6 px-8 bg-gradient-to-b from-white to-[#F7F7F7] rounded-lg shadow-lg">
                <img
                src="/images/leo.jpg"
                alt=""
                class="absolute rounded-full w-14 h-14 -left-6 -top-6"
                />
                <p class="text-purple-950 text-lg font-medium">
                    “Sou cliente há muito tempo e sempre recuso outras ofertas, não troco a Vmar por nenhuma concorrência. Durante esses anos nunca me decepcionou, sempre nos atendendo da melhor forma.”
                </p>
                <p class="mt-6 mb-2 text-purple-950">Leonardo Rodrigues</p>
            </div>
        </div>
      </div>
      <a
          href="#planos"
          className="rounded-md bg-orange-600 hover:bg-orange-700 transition-all px-8 py-4 text-md font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
        >
          Contrate agora mesmo
      </a>
    </section>
  );
};
