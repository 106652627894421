import { useState, useRef, useEffect } from "react";
import { EthernetPort } from "../assets/ethernetPort";
import { LucideGlobe } from "../assets/lucideGlobe";
import { LucideChevronsLeft } from "../assets/lucideChevronsLeft";
import { LucideHouse } from "../assets/lucideHouse";

import PorqueCard from "./PorqueCard";

export default function Porque(){
    const [isIntersecting, setIsIntersecting] = useState(false);
    // const [isIntersectingdois, setIsIntersectingdois] = useState(false);

    const ref = useRef(null);
    // const refdois = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
            setIsIntersecting(entry.isIntersecting);
            },
            { rootMargin: "-80px" }
        );
        console.log(isIntersecting);
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, [isIntersecting]);


    useEffect(() => {
        if (isIntersecting) {
            ref.current.querySelectorAll("div, h2").forEach((el) => {
            el.classList.add("slide-in");
            el.classList.remove("slide-out-left")
            });
        } else {
            ref.current.querySelectorAll("div, h2").forEach((el) => {
            el.classList.remove("slide-in");
            el.classList.add("slide-out-left")
            });
        }
    }, [isIntersecting]);

    //cards diferenciais

    // useEffect(() => {
    //     const observerdois = new IntersectionObserver(
    //     ([entry]) => {
    //         setIsIntersectingdois(entry.isIntersecting);
    //     },
    //     { rootMargin: "-5px" }
    //     );
    //     console.log(isIntersectingdois, 'a');
    //     observerdois.observe(refdois.current);
    //     return () => observerdois.disconnect();
    //     }, [isIntersectingdois]);


    // useEffect(() => {
    //     if (isIntersectingdois) {
    //         refdois.current.querySelectorAll("div, h2").forEach((el) => {
    //         el.classList.add("slide-in");
    //         el.classList.remove("slide-out-left")
    //         });
    //     } else {
    //         refdois.current.querySelectorAll("div, h2").forEach((el) => {
    //         el.classList.remove("slide-in");
    //         el.classList.add("slide-out-left")
    //         });
    //     }
    // }, [isIntersectingdois]);

    return (
    <section className="bg-gray-100 py-20 w-full flex flex-col items-center justify-center" id="diferenciais">
        <h2 className="text-[26px] font-bold tracking-tight sm:text-4xl text-purple-950 transition-all duration-[850ms]">Por que escolher a VMAR?</h2>
        <div className="px-2 py-4 lg:py-10 sm:px-20 flex flex-col lg:flex-row gap-10 w-full transition-all duration-[850ms]">
            <div className="py-8 flex flex-wrap lg:w-1/2 justify-center gap-6">
                <div className="px-4 lg:pb-0 lg:px-10">
                    <p className="text-center text-xl leading-8 lg:text-justify text-purple-800">Na era digital, escolher uma conexão de internet de qualidade é fundamental para o sucesso de empresas e para a satisfação dos consumidores. Nós, da VMAR Telecom, entendemos a complexidade das redes, dos sistemas de telecomunicação e da tecnologia necessária para fornecer um serviço que vai além do básico. Nossa equipe é composta por profissionais especializados, que conhecem a fundo as necessidades do mercado e sabem como adaptar as melhores soluções de conectividade para cada cliente.</p>
                </div>
                <div ref={ref} className="w-full flex flex-wrap justify-center gap-6 transition-all duration-[850ms]">
                    <PorqueCard titulo={"Instalação em até 24 horas"} svg={<EthernetPort />}/>
                    <PorqueCard titulo={"Velocidade Ultrarrápida: Navegue, baixe e faça streaming sem interrupções"} svg={<LucideGlobe />} />
                    <PorqueCard titulo={"Estabilidade e Confiabilidade: Sem quedas, sem lentidão"} svg={<LucideChevronsLeft />} />
                    <PorqueCard titulo={"Perfeito para Home Office: Trabalhe com segurança e eficiência"} svg={<LucideHouse />} />
                </div>
            </div>
            <div className="lg:w-1/2 flex flex-col items-center justify-center">
                <img className="rounded-xl animate-[saltar_3s_ease-in-out_infinite]" src="/images/work.jpg" />
            </div>
        </div>
        <div className="mt-20 mx-auto flex items-center justify-center gap-x-6 lg:justify-start transition-all duration-[850ms]">
                <a
                  href="#planos"
                  className="rounded-md bg-purple-900 hover:bg-purple-950 transition-all px-8 py-4 text-md font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Quero contratar
                </a>
        </div>
      </section>
    );
  };
