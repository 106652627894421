import { CheckIcon } from '@heroicons/react/20/solid'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

const tiers = [
  {
    name: 'Basic',
    id: 'tier-basic',
    href: 'https://api.whatsapp.com/send/?phone=55996026060&text=Ol%C3%A1,%20vim%20do%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20plano%20Basic&type=phone_number&app_absent=0',
    priceMonthly: 'R$ 79,90',
    description: "200Mb",
    features: ['Internet Ultraveloz', 'Instalação GRATUITA em 24 horas', 'Fibra Óptica', 'Suporte técnico'],
    featured: false,
  },
  {
    name: 'Total',
    id: 'tier-total',
    href: 'https://api.whatsapp.com/send/?phone=55996026060&text=Ol%C3%A1,%20vim%20do%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20plano%20Total&type=phone_number&app_absent=0',
    priceMonthly: 'R$ 99,90',
    description: '400Mb',
    features: ['Internet Ultraveloz', 'Instalação GRATUITA em 24 horas', 'Fibra Óptica', 'Suporte técnico'],
    featured: true,
  },
  {
    name: 'Premium',
    id: 'tier-premium',
    href: 'https://api.whatsapp.com/send/?phone=55996026060&text=Ol%C3%A1,%20vim%20do%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20plano%20Premium&type=phone_number&app_absent=0',
    priceMonthly: 'R$ 119,90',
    description: "700Mb",
    features: ['Internet Ultraveloz', 'Instalação GRATUITA em 24 horas', 'Fibra Óptica', 'Suporte técnico'],
    featured: false,
  },
]

const tiersWithChannels = [
    {
      name: 'Basic + Canais',
      id: 'tier-basic-channels',
      href: 'https://api.whatsapp.com/send/?phone=55996026060&text=Ol%C3%A1,%20vim%20do%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20plano%20Basic%20e%20Canais',
      priceMonthly: 'R$ 119,90',
      description: "200Mb + Canais ao vivo",
      features: ['Internet Ultraveloz', 'Instalação GRATUITA em 24 horas', 'Fibra Óptica', 'Suporte técnico', 'Mais de 50 canais ao vivo'],
      featured: false,
    },
    {
      name: 'Total + Canais',
      id: 'tier-total-channels',
      href: 'https://api.whatsapp.com/send/?phone=55996026060&text=Ol%C3%A1,%20vim%20do%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20plano%20Total%20e%20Canais',
      priceMonthly: 'R$ 139,90',
      description: '400Mb + Canais ao vivo',
      features: ['Internet Ultraveloz', 'Instalação GRATUITA em 24 horas', 'Fibra Óptica', 'Suporte técnico', 'Mais de 50 canais ao vivo'],
      featured: true,
    },
    {
      name: 'Premium + Canais',
      id: 'tier-premium-channels',
      href: 'https://api.whatsapp.com/send/?phone=55996026060&text=Ol%C3%A1,%20vim%20do%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20plano%20Premium%20e%20Canais',
      priceMonthly: 'R$ 159,90',
      description: "700Mb + Canais ao vivo",
      features: ['Internet Ultraveloz', 'Instalação GRATUITA em 24 horas', 'Fibra Óptica', 'Suporte técnico', 'Mais de 50 canais ao vivo'],
      featured: false,
    },
  ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Planos() {
  return (
    <div className="bg-gray-100 px-1.5 py-24 lg:px-16 flex flex-col gap-20 scroll-smooth" id="planos">
      <div className="mx-auto max-w-2xl text-center lg:max-w-4xl">
        <h2 className="text-3xl font-medium tracking-tight text-purple-900 sm:text-5xl">Nós temos o <b className='text-purple-950'>plano perfeito </b>para você!</h2>
      </div>
      <div >
        <Tabs isFitted variant='unstylled'>
            <TabList mb='1em' maxW={'xl'} mx={'auto'}>
                <Tab className='bg-purple-300 rounded-tl-lg rounded-bl-lg' _selected={{ color: 'white', bg: 'purple.800' }}>Internet</Tab>
                <Tab className='bg-purple-300 rounded-tr-lg rounded-br-lg' _selected={{ color: 'white', bg: 'purple.800' }}>Internet + Canais</Tab>
            </TabList>
            <TabPanels className="mx-auto mt-16 max-w-lg items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:w-full lg:max-w-7xl">
                <TabPanel data-state="open" _open={{animation: "fade-in 600ms ease-out"}} className='w-full grid grid-cols-1 lg:grid-cols-3 max-w-full gap-4'>
                    {tiers.map((tier, tierIdx) => (
                        <div
                            key={tier.id}
                            className={classNames(
                            tier.featured ? 'bg-gradient-to-b from-orange-400 to-orange-600 shadow-2xl' : 'bg-gradient-to-b from-purple-800 to-purple-950 sm:mx-8 lg:mx-0',
                            'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10',
                            )}
                        >
                            <h3
                            id={tier.id}
                            className={classNames(
                                'text-white',
                                'text-2xl font-semibold leading-7',
                            )}
                            >
                            {tier.name}
                            </h3>
                            <p className={classNames(tier.featured ? 'text-white' : 'text-white', 'mt-6 text-base leading-7')}>
                            {tier.description}
                            </p>
                            <p className="mt-4 flex items-center justify-center lg:items-baseline gap-x-2">
                              <span
                                  className={classNames(
                                  tier.featured ? 'text-white' : 'text-white',
                                  'text-5xl font-bold tracking-tight',
                                  )}
                              >
                                  {tier.priceMonthly}
                              </span>
                              <span className={classNames(tier.featured ? 'text-white' : 'text-white', 'text-base')}>/mês</span>
                            </p>
                            <ul
                            role="list"
                            className={classNames(
                                tier.featured ? 'text-white' : 'text-white',
                                'mt-8 space-y-3 text-sm leading-6 sm:mt-10',
                            )}
                            >
                            {tier.features.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                <CheckIcon
                                    aria-hidden="true"
                                    className={classNames(tier.featured ? 'text-white' : 'text-white', 'h-6 w-5 flex-none')}
                                />
                                {feature}
                                </li>
                            ))}
                            </ul>
                            <a
                            href={tier.href}
                            aria-describedby={tier.id}
                            className={classNames(
                                tier.featured
                                ? 'bg-purple-900 text-white shadow-sm hover:bg-purple-950'
                                : 'text-white bg-orange-600 hover:bg-orange-700',
                                'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold sm:mt-10 transition-all',
                            )}
                            >
                            Contratar Online
                            </a>
                        </div>
                    ))}
                </TabPanel>
                <TabPanel data-state="open" _open={{animation: "fade-in 600ms ease-out"}} className='w-full grid grid-cols-1 lg:grid-cols-3 max-w-full gap-4'>
                {tiersWithChannels.map((tier, tierIdx) => (
                        <div
                            key={tier.id}
                            className={classNames(
                            tier.featured ? 'bg-gradient-to-b from-purple-800 to-purple-950 shadow-2xl' : 'bg-gradient-to-b from-orange-400 to-orange-600 sm:mx-8 lg:mx-0',
                            'rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10',
                            )}
                        >
                            <h3
                            id={tier.id}
                            className={classNames(
                                'text-white',
                                'text-2xl font-semibold leading-7',
                            )}
                            >
                            {tier.name}
                            </h3>
                            <p className={classNames(tier.featured ? 'text-white' : 'text-white', 'mt-6 text-base leading-7')}>
                            {tier.description}
                            </p>
                            <p className="mt-4 items-center justify-center sm:items-baseline gap-x-2">
                            <span
                                className={classNames(
                                tier.featured ? 'text-white' : 'text-white',
                                'text-5xl font-bold tracking-tight',
                                )}
                            >
                                {tier.priceMonthly}
                            </span>
                            <span className={classNames(tier.featured ? 'text-white' : 'text-white', 'text-base')}>/mês</span>
                            </p>
                            <ul
                            role="list"
                            className={classNames(
                                tier.featured ? 'text-white' : 'text-white',
                                'mt-8 space-y-3 text-sm leading-6 sm:mt-10',
                            )}
                            >
                            {tier.features.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                <CheckIcon
                                    aria-hidden="true"
                                    className={classNames(tier.featured ? 'text-white' : 'text-white', 'h-6 w-5 flex-none')}
                                />
                                {feature}
                                </li>
                            ))}
                            </ul>
                            <a
                            href={tier.href}
                            aria-describedby={tier.id}
                            className={classNames(
                                tier.featured
                                ? 'bg-orange-600 text-white shadow-sm hover:bg-orange-700'
                                : 'text-white bg-purple-900 hover:bg-purple-950',
                                'mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold sm:mt-10 transition-all',
                            )}
                            >
                            Contratar Online
                            </a>
                        </div>
                    ))}
                </TabPanel>
            </TabPanels>
        </Tabs>
        <div className='flex flex-col gap-10 pt-10 px-8 items-center'>
          <p className='text-lg leading-8 text-purple-950 sm:text-xl'>Dúvidas sobre qual plano escolher? Temos um especialista para te ajudar. Clique no botão abaixo e entre em contato</p>
          <a
            href="https://api.whatsapp.com/send/?phone=55996026060&text=Ol%C3%A1,%20vim%20do%20seu%20site%20e%20gostaria%20de%20falar%20com%20um%20especialista"
            className="rounded-md bg-purple-900 hover:bg-purple-950 transition-all px-8 py-4 text-md font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
          >
            Falar com um especialista
          </a>
        </div>
      </div>
    </div>
  )
}
