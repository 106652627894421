import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Depoimentos from './components/Depoimentos';
import Diferenciais from './components/Diferenciais';
import Planos from './components/Planos';
import Navbar from './components/Navbar';
import { ChakraProvider } from '@chakra-ui/react';
import Footer from './components/Footer';
import Sobre from './components/Sobre';
import Porque from './components/Porque';

function App() {
  return (
    <div className="App">
      <ChakraProvider>
        <Navbar />
        <Header />
        <Sobre />
        <Diferenciais />
        <Porque />
        <Depoimentos />
        <Planos />
        <Footer />
      </ChakraProvider>
    </div>
  );
}

export default App;
