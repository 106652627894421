import { useState, useRef, useEffect } from "react";


export default function Sobre(){
    // const [isIntersecting, setIsIntersecting] = useState(false);

    // const ref = useRef(null);

    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //       ([entry]) => {
    //         setIsIntersecting(entry.isIntersecting);
    //       },
    //       { rootMargin: "-80px" }
    //     );
    //     console.log(isIntersecting);
    //     observer.observe(ref.current);
    //     return () => observer.disconnect();
    // }, [isIntersecting]);

    // useEffect(() => {
    //     if (isIntersecting) {
    //       ref.current.querySelectorAll("div, h2").forEach((el) => {
    //         el.classList.add("slide-in");
    //         el.classList.remove(")
    //       });
    //     } else {
    //       ref.current.querySelectorAll("div, h2").forEach((el) => {
    //         el.classList.remove("slide-in");
    //         el.classList.add(")
    //       });
    //     }
    //   }, [isIntersecting]);

    return (
        <section id="sobre">
            <div className="bg-gray-100 px-6 py-10 lg:py-10 xl:py-20 shadow-2xl sm:px-32 flex-col flex justify-center lg:flex-row  lg:justify-around lg:px-10 xl:px-24 items-center">
                <h2 className="lg:hidden text-2xl font-bold tracking-tight text-purple-950 sm:text-3xl">
                    VMAR Telecom: Soluções em Internet com Excelência
                </h2>
                <div className="pt-10 lg:pt-0">
                    <img
                        alt="App screenshot"
                        src="/images/coworking.jpg"
                        // width={1824}
                        // height={1080}
                        className="w-full lg:w-[20rem] xl:w-[30rem] rounded-xl animate-[saltar_3s_ease-in-out_infinite]"
                    />
                </div>
                <div className="max-w-xl text-center lg:text-left">
                    <h2 className="hidden lg:block text-2xl font-bold tracking-tight text-purple-950 sm:text-3xl">
                    VMAR Telecom: Soluções em Internet com Excelência
                    </h2>
                    <p className="mt-6 text-lg leading-8 text-center lg:text-left text-purple-900 sm:text-xl">
                    Na VMAR Telecom, transformamos o conhecimento em conectividade de alta performance. Com mais de 20 anos de experiência no setor, oferecemos consultoria especializada em internet e uma ampla gama de soluções para garantir que nossos clientes tenham acesso ao melhor em serviços de tecnologia e internet.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                        <a
                        href="#planos"
                        className="rounded-md bg-purple-900 hover:bg-purple-950 transition-all px-3.5 py-2.5 text-md font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                        Conheça nossos planos 
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}