import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'


export default function Header() {
    return (
        <header className="w-full">
          <div className="bg-bg-header bg-fixed  bg-cover bg-blend-overlay bg-purple-950 px-6 py-16 lg:py-10 xl:py-4 shadow-2xl sm:px-32 flex-col flex justify-center lg:flex-row  lg:justify-around lg:px-10 xl:px-24 items-center">
            <div className="max-w-xl text-center lg:text-left">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-5xl">
                A melhor internet fibra do Rio Grande do Sul 
              </h2>
              <p className="mt-6 text-lg leading-8 text-white sm:text-xl">
              Você merece a potência da internet de fibra óptica na sua casa ou empresa, com planos que cabem no seu bolso!
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                <a
                  href="#planos"
                  className="rounded-md bg-orange-600 hover:bg-orange-700 transition-all px-3.5 py-2.5 text-md font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Conheça nossos planos 
                </a>
              </div>
            </div>
            <div className="pt-10 lg:pt-0">
              <img
                alt="App screenshot"
                src="/images/home-office.jpg"
                // width={1824}
                // height={1080}
                className="w-full lg:w-[20rem] xl:w-[30rem] rounded-xl"
              />
            </div>
          </div>
        </header>
    )
  }
  