import { useState, useRef, useEffect } from "react";

export default function DiferencialCard(props){
    // const [isIntersecting, setIsIntersecting] = useState(false);

    // const ref = useRef(null);
  
    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //       ([entry]) => {
    //         setIsIntersecting(entry.isIntersecting);
    //       },
    //       { rootMargin: "-80px" }
    //     );
    //     console.log(isIntersecting);
    //     observer.observe(ref.current);
    //     return () => observer.disconnect();
    // }, [isIntersecting]);
    
  
    // useEffect(() => {
    //     if (isIntersecting) {
    //       ref.current.querySelectorAll("svg, h3").forEach((el) => {
    //         el.classList.add("slide-in");
    //         el.classList.remove("slide-out-left")
    //       });
    //     } else {
    //       ref.current.querySelectorAll("svg, h3").forEach((el) => {
    //         el.classList.remove("slide-in");
    //         el.classList.add("slide-out-left")
    //       });
    //     }
    // }, [isIntersecting]);

    return(
        <div className="bg-gradient-to-b from-orange-400 to-orange-600 text-white text-center text-wrap rounded-2xl shadow-xl w-[65%] p-10 lg:w-[40%] xl:p-8 flex flex-col items-center justify-center gap-2 transition-all duration-[850ms]">
            {props.svg}
            <h3 className="text-xl font-medium transition-all duration-[850ms]">{props.titulo}</h3>
        </div>
    )
}