import { useState, useRef, useEffect } from "react";
import { EthernetPort } from '../assets/ethernetPort.jsx'
import { LucideCog } from "../assets/lucideCog.jsx";
import { LucideWifi } from "../assets/lucideWifi.jsx";
import { LucideDolar } from "../assets/lucideDolar.jsx";

import DiferencialCard from "./DiferencialCard";


export default function Diferenciais(){
  const [isIntersecting, setIsIntersecting] = useState(false);
  // const [isIntersectingdois, setIsIntersectingdois] = useState(false);

  const ref = useRef(null);
  // const refdois = useRef(null);

  useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsIntersecting(entry.isIntersecting);
        },
        { rootMargin: "-80px" }
      );
      console.log(isIntersecting);
      observer.observe(ref.current);
      return () => observer.disconnect();
  }, [isIntersecting]);
  

  useEffect(() => {
      if (isIntersecting) {
        ref.current.querySelectorAll("div, h2").forEach((el) => {
          el.classList.add("slide-in");
          el.classList.remove("slide-out-left")
        });
      } else {
        ref.current.querySelectorAll("div, h2").forEach((el) => {
          el.classList.remove("slide-in");
          el.classList.add("slide-out-left")
        });
      }
  }, [isIntersecting]);

  //cards diferenciais

  // useEffect(() => {
  //   const observerdois = new IntersectionObserver(
  //     ([entry]) => {
  //       setIsIntersectingdois(entry.isIntersecting);
  //     },
  //     { rootMargin: "-5px" }
  //   );
  //     console.log(isIntersectingdois, 'a');
  //     observerdois.observe(refdois.current);
  //     return () => observerdois.disconnect();
  //   }, [isIntersectingdois]);


  // useEffect(() => {
  //     if (isIntersectingdois) {
  //       refdois.current.querySelectorAll("div, h2").forEach((el) => {
  //         el.classList.add("slide-in");
  //         el.classList.remove("slide-out-left")
  //       });
  //     } else {
  //       refdois.current.querySelectorAll("div, h2").forEach((el) => {
  //         el.classList.remove("slide-in");
  //         el.classList.add("slide-out-left")
  //       });
  //     }
  // }, [isIntersectingdois]);

  return (
    <section className="bg-bg-diferencial bg-fixed bg-purple-950 bg-blend-overlay bg-cover py-20 w-full flex flex-col items-center justify-center" id="diferenciais">
      <h2 className="text-[26px] font-bold tracking-tight text-white sm:text-4xl transition-all duration-[850ms]">Nosso diferencial</h2>
      <div className="px-2 py-10 sm:px-20 flex flex-col lg:flex-row gap-10 w-full transition-all duration-[1050ms]">
        <div className="lg:w-1/2 flex flex-col items-center justify-center">
          <img className="rounded-xl animate-[saltar_3s_ease-in-out_infinite]" src="images/familia-1.jpg" />
        </div>
        <div className="py-4 flex flex-wrap lg:w-1/2 justify-center gap-6">
          <div className="px-4 pb-10 lg:pb-0 lg:px-10">
            <p className="text-center text-xl leading-8 lg:text-justify text-white">Não somos apenas uma empresa de vendas de internet; somos consultores e especialistas que entendem profundamente o que nossos clientes precisam. Com o compromisso de fornecer serviços que garantam estabilidade, velocidade e alta qualidade, trabalhamos lado a lado com os melhores provedores de internet, garantindo que eles possam oferecer o melhor serviço, utilizando nossa expertise e suporte técnico.           </p>
          </div>
          <div ref={ref} className="w-full flex flex-wrap justify-center gap-6 transition-all duration-[850ms]">
            <DiferencialCard titulo={"Instalação grátis"} svg={<EthernetPort />}/>
            <DiferencialCard titulo={"Suporte 24h"} svg={<LucideCog />}/>
            <DiferencialCard titulo={"Modem wi-fi grátis"} svg={<LucideWifi />}/>
            <DiferencialCard titulo={"1º pagamento em até 30 dias"} svg={<LucideDolar />}/>
          </div>
        </div>
      </div>
      <div className="mt-20 mx-auto flex items-center justify-center gap-x-6 lg:justify-start transition-all duration-[850ms]">
              <a
                href="#planos"
                className="rounded-md bg-orange-600 hover:bg-orange-700 transition-all px-8 py-4 text-md font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Quero contratar
              </a>
      </div>
    </section>
  );
};
  